<template>
      <div v-if="loanList.length && JSON.stringify(UserInfo)!== '{}'" style="height: 100%;display: flex;">
        <el-card>
          <div style="font-size: 1.125rem">融资管理</div>
          <div class="financial_management">
            <span>{{loanList[0].product_name}}</span>
            <span>{{loanList[0].product_desc}}</span>
            <span>额度：{{loanList[0].quota}}</span>
            <span>年利率：{{loanList[0].year_rate}}</span>
            <span>周期：{{loanList[0].cycle_day || 0}}天</span>
            <span>还款方式：{{loanList[0].return_type}}</span>
            <span>支持平台：{{['MercadoLibre'][loanList[0].platform-1]}}</span>
            <el-button  style="width: 100%;font-size: 1.5rem;" :class="['main_color main_btn4','view_progress main_btn5','loans_success main_btn6','loans_error main_btn7'][OrderStatus]" @click="ToApply(loanList[0].id,loanList[0].order_id)">{{['立即申请','查看审核进度','审批通过','已驳回(重新申请)'][OrderStatus]}}</el-button>
          </div>
        </el-card>
        <div style="margin-left:1.5rem;flex: 1;height: 100%">
          <el-card style="height: 48% ;margin-bottom:1.5rem;">
            <div style="font-size: 1.125rem">
              个人中心
              <div style="float: right"><el-link style="font-size: 1.125rem" type="primary" @click="$router.push('/personal_center')">查看详情</el-link></div>
            </div>
            <div style='display: flex;flex-direction: column;align-items: center;width: 100%'>
              <div style="font-size: 2rem">贷款余额</div>
              <img :src="require('@/assets/loans.png')" alt="" style="width: 12.5rem">
              <div style="font-size: 4.25rem;">¥{{UserInfo.loan_balance}}</div>
            </div>
          </el-card>
          <el-card style="height: 48%">
            <div style="font-size: 1.125rem">
              推广返利
              <div style="float: right"><el-link style="font-size: 1.125rem" type="primary" @click="$router.push('/Promotion_rebate')">查看详情</el-link></div>
            </div>
            <div style='display: flex;flex-direction: column;align-items: center;width: 100%'>
              <div style="font-size: 2rem">可提现金额</div>
              <img :src="require('@/assets/withdraw_deposit.png')" alt="" style="width: 12.5rem">
              <div style="font-size: 4.25rem;">¥{{UserInfo.balance}}</div>
            </div>
          </el-card>
        </div>
      </div>
</template>
<script>
export default {
  name: "dashboard",
  data(){
    return {
        UserInfo:{},
        //订单当前状态
        OrderStatus:0,
        //融资信息
        loanList:[],
    }
  },
  mounted(){
    this.GetUserInfo()
    this.GetGoodsInfo()
  },
  methods:{
    //获取用户信息
    GetUserInfo(){
      this.$api.post('v1/api/makafin/base_account/get',{},res=>{
        this.UserInfo=res
      })
    },
    //获取融资信息
    GetGoodsInfo(){
      this.$api.post('v1/api/makafin/product/tabulation',{},res=>{
        res.list.forEach(item=>{
          if(item.order_status===4){
            item.order_status=0
          }
        })
        this.loanList=res.list
        this.OrderStatus=res.list[0].order_status
      })

    },
    //申请授信
    ToApply(id,orderID){
      let ActiveInd=0
      if(this.OrderStatus===1 || this.OrderStatus===3){
        ActiveInd=2
      }else if(this.OrderStatus===2){
        ActiveInd=3
      }
      this.$utils.CacheSessionSet('ActiveInd',ActiveInd)
      this.$utils.CacheSessionSet('Goods_id',id)
      this.$utils.CacheSessionSet('order_id',orderID)
      this.$router.push({name:'Accredit',params:{ActiveInd:ActiveInd,id:id}})
    }
  }
}
</script>
<style scoped>
.financial_management{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3.5rem 14.75rem;
}
.financial_management >span:nth-child(1){
  font-size: 2.875rem;
  color:#FFBA56;
  margin-bottom: 2rem;
}
.main_color{
  background: #FFBA56;
  color:#333;
}
.view_progress{
  background: #FF914D;
  color:#FFF;
}
.loans_success{
  background: #3DBF00;
  color:#FFF;
}
.loans_error{
  background: #BF0000;
  color:#FFF;
}
.financial_management >span{
  font-size: 2rem;
  line-height: 1.97rem;
  color: #333333;
  padding:1.3rem 0;
}
</style>